<template>
  <v-card flat tile class="fill-height d-flex flex-column justify-space-between pt-5 pb-1">
    <v-window v-model="onboarding" class="fill-height">
      <v-window-item v-for="(boardingItem, index) in boradingItems" :key="`boarding-card-${index}`" class="fill-height">
        <v-card-text class="text-center d-flex flex-column align-center justify-center fill-height">
          <v-img max-height="175" contain :src="boardingItem.img"></v-img>
          <div class="text-h5 font-weight-bold my-5 primary--text">{{ boardingItem.title }}</div>
          <div v-html="boardingItem.desc"></div>
        </v-card-text>
      </v-window-item>
    </v-window>

    <v-card-text class="d-flex flex-column justify-center align-center" v-if="!nextShow">
      <v-btn text color="grey" class="text-capitalize" @click="$router.push({ name: 'login' })">
        Giriş yaparak devam et
      </v-btn>
      <v-btn text color="primary" class="text-capitalize" @click="start">Yeni başlıyorum </v-btn>
    </v-card-text>

    <v-card-actions class="justify-space-between px-5">
      <v-btn icon @click="prev" :disabled="prevDisabled">
        <v-icon v-text="'fa-chevron-left'"></v-icon>
      </v-btn>
      <v-item-group v-model="onboarding" class="text-center" mandatory>
        <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }">
          <v-btn :input-value="active" icon @click="toggle">
            <v-icon color="primary" v-text="'fa-circle'"></v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn icon @click="next" v-if="nextShow">
        <v-icon v-text="'fa-chevron-right'"></v-icon>
      </v-btn>
      <v-btn icon @click="start" v-else>
        <v-icon color="green" v-text="'fa-check'"></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { RESET_STATE } from '@/store/modules/carWizard.module';

export default {
  name: 'Tour',
  components: {},
  data() {
    return {
      length: 3,
      onboarding: 0,
      boardingItems: [
        {
          img: 'https://storage.googleapis.com/leadgenb-static/super-app-assets/undraw_switches_1js3.svg',
          title: 'Araç Bilgilerinizi Girin',
          desc: '<ul class="text-left"><li class="my-2">Mevcut Araçlarınızı hızlıca tanımlayın.</li><li class="my-2">Aracınıza ait pazar bilgilerini takip edin, fırsatları önceden yakalayın.</li><li>İsterseniz hayalinizdeki aracı tanımlayın, size özel önerileri görün</li></ul>',
        },
        {
          img: 'https://storage.googleapis.com/leadgenb-static/super-app-assets/undraw_transfer_money_re_6o1h.svg',
          title: 'Aracınızı Değerleyin',
          desc: '<ul class="text-left"><li class="my-2">Aracınızın tek tuşla güncel değerini öğrenin.</li><li class="my-2">İsterseniz size özel en iyi satınalma teklifini alın.</li><li>Servis/Kasko kodu/Tramer gibi hizmetlerden avantajlı fiyatlardan yararlanma fırsatı edinin</li></ul>',
        },
        {
          img: 'https://storage.googleapis.com/leadgenb-static/super-app-assets/undraw_segment_analysis_re_ocsl.svg',
          title: 'Analizlerinizi Görün',
          desc: '<ul class="text-left"><li class="my-2">Aracınızın güncel değeri ve detaylı analizlerini düzenli bilgi edinme imkanı sağlayın.</li></ul>',
        },
      ],
    };
  },
  computed: {
    boradingItems() {
      return this.boardingItems;
    },
    prevDisabled() {
      return this.onboarding === 0;
    },
    nextShow() {
      return this.onboarding < this.boradingItems.length - 1;
    },
  },
  methods: {
    ...mapActions({
      resetState: RESET_STATE,
    }),
    next() {
      this.onboarding = this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    start() {
      this.resetState();
      this.$router.push({ name: 'wizard' });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
